import * as prismic from "@prismicio/client";
import * as prismicH from "@prismicio/helpers";
import mappings from "./prismic-mappings";

const endpoint = prismic.getEndpoint("oaktyresmainsite");
const accessToken =
  "MC5Ya0xieXhNQUFDQUFxWTlw.Qe-_ve-_ve-_vWzvv71kaQkh77-977-977-9GEfvv73vv73vv73vv73vv70g77-9OgQePAAmPATvv71w";
const routes = [
  // Update to match your website's URL structure
  { type: "davant_italy", path: "/" },
];

const client = prismic.createClient(endpoint, { routes, accessToken });

const init = async () => {
  const doc = await client.getSingle("davant_italy");
  const data = doc.data;

  if (!data) {
    throw new Error("no data found in document");
  }
  // is document ready
  const runner = () => {
    // helper fn to modify elements
    const replaceText = (selector, value, attribute = null) => {
      const el = document.querySelector(selector);
      if (el) {
        if (attribute) {
          el.setAttribute(attribute, value);
        } else {
          el.innerHTML = value;
        }
      }
    };

    // iterate through all content parts
    mappings.map((spec) => {
      const element = spec.selector
        ? document.querySelector(spec.selector)
        : false;
      const entry = data[spec.property];

      // render content from prismic
      if (!entry) {
        throw new Error(`entry ${spec.property} missing from Prismic object`);
      }

      // based on mode
      switch (spec.mode) {
        case "title":
          replaceText("title", entry);
          replaceText('meta[property="og:title"]', entry, "content");
          break;

        case "description":
          replaceText('meta[name="description"]', entry, "content");
          replaceText('meta[property="og:description"]', entry, "content");
          break;

        case "nested-mailto":
          if (element) {
            const link = element.querySelector("a[href]");
            if (link) {
              link.setAttribute("href", `mailto:${entry}`);
              link.innerText = entry;
            }
          }
          break;

        case "self-href":
          if (element) {
            element.href = entry.url;
          }
          break;

        case "rich":
          const content_html = prismicH.asHTML(entry);
          if (element) element.innerHTML = content_html;
          break;

        case "plain":
        default:
          if (element) element.innerText = entry;
      }
      // fade element in
      if (["self-href", "title", "description"].indexOf(spec.mode) === -1) {
        element.classList.add("activated");
      }
    });
  };

  document.addEventListener("DOMContentLoaded", runner);
  if (document.readyState != "loading") {
    runner();
  }
};

init();
